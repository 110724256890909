import './App.css';
import HomePage from './pages/HomePage';
import { Router, Routes, Route } from 'react-router-dom';
import BricksPage from './pages/BricksPage';
import CaulkingPage from './pages/CaulkingPage';
import ConcretePage from './pages/ConcretePage';
import WaterproofingPage from './pages/waterproofingpage';
import PaintingPage from './pages/PaintingPage';
import EifsStuccoPage from './pages/EifsStuccoPage';
import OurProjects from './pages/OurProjects';

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/masonryandbrick' element={<BricksPage />} />
      <Route path='/caulking' element={<CaulkingPage />} />
      <Route path='/concrete' element={<ConcretePage />} />
      <Route path='/waterproofing' element={<WaterproofingPage />} />
      <Route path='/painting' element={<PaintingPage />} />
      <Route path='/eifsstucco' element={<EifsStuccoPage />} />
      <Route path='/projects' element={<OurProjects />} />
    </Routes>
  );
}

export default App;
