import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Hero from '../components/Hero';
import ServiceGrid from '../components/ServiceGrid';
import ContactUs from '../components/ContactUs';
import AboutUs from '../components/AboutUs';
import TextSlider from '../components/TextSlider';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function HomePage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa] min-h-screen'>
      <Helmet>
        <title>
          Home - VBM Construction | Expert Construction & Restoration Services
        </title>
        <meta
          name='description'
          content='Welcome to VBM Construction. Discover our expert construction and restoration services, including residential and commercial projects. Learn more about our quality craftsmanship and how we can assist you.'
        />
        <meta
          name='keywords'
          content='construction, restoration, VBM Construction, home renovation, commercial construction, residential services, expert craftsmanship'
        />
      </Helmet>
      <Header />
      <Hero />
      <AboutUs />
      <TextSlider />
      <ServiceGrid />
      <ContactUs />
    </div>
  );
}

export default HomePage;
