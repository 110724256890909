import React, { useEffect, useState } from 'react';

function Hero() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  return (
    <section className='relative text-center overflow-hidden'>
      <img
        src='hero4.jpg'
        alt='Construction Restoration'
        className='w-full h-[32rem] md:h-[48rem] object-cover object-top '
      />
      <div className='absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 p-6 md:p-12'>
        <h1 className='text-white text-4xl md:text-6xl font-bold flex flex-col items-center space-y-4 md:space-y-6'>
          <span className='animate-word text-6xl md:text-8xl'>Restore</span>
          <span className='animate-word text-6xl md:text-8xl'>Renew</span>
          <span className='animate-word text-6xl md:text-8xl'>Reimagine</span>
        </h1>
      </div>
    </section>
  );
}

export default Hero;
