import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function EifsStuccoPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa]'>
      <Helmet>
        <title>EIFS and Stucco Restoration Services | VBM Construction</title>
        <meta
          name='description'
          content='VBM Construction provides expert EIFS and stucco restoration services, enhancing durability, moisture management, and energy efficiency. Prevent damage and improve your property with our professional solutions.'
        />
        <meta
          name='keywords'
          content='EIFS restoration, stucco repair, stucco resurfacing, EIFS services, moisture management, energy efficiency, VBM Construction'
        />
      </Helmet>
      <Header />
      <div className='h-auto bg-[#2b2b2b] flex items-center justify-center py-10'>
        <h1 className='text-[#fafafa] text-4xl md:text-5xl lg:text-6xl px-4 text-center'>
          EIFS/Stucco Restoration
        </h1>
      </div>
      <div className='h-auto my-8 md:my-16 flex flex-col md:flex-row'>
        <div className='bg-[#2b2b2b] py-8 px-6 md:w-1/2'>
          <h2 className='text-[#fafafa] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Benefits of EIFS/Stucco Restoration
          </h2>
          <ul className='text-[#fafafa] space-y-2'>
            {[
              'Enhanced Durability: Extends the lifespan of exterior surfaces and prevents future damage.',
              'Moisture Management: Updates moisture barriers to prevent water infiltration and damage.',
              'Improved Energy Efficiency: Restores insulating properties, reducing heating and cooling costs.',
              'Crack and Damage Prevention: Repairs existing issues and helps prevent new damage.',
              'Cost-Effective Renovation: Offers a cost-effective way to refresh exterior surfaces without full replacement.',
            ].map((text, index) => (
              <li key={index} className='flex items-center'>
                <GoDotFill className='text-[#fafafa] text-base mr-3 flex-shrink-0' />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className='py-8 px-6 md:w-1/2 md:pl-10'>
          <h2 className='text-[#2b2b2b] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Our EIFS/Stucco Services
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Crack Repair',
                'Resurfacing',
                'Texture Matching',
                'Repainting',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Damage Assessment',
                'EIFS/Stucco Cleaning',
                'Seam and Joint Repair',
                'Ventilation Installation',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
          </div>
          <h3 className='mt-4'>and more...</h3>
        </div>
      </div>

      <ContactUs />
    </div>
  );
}

export default EifsStuccoPage;
