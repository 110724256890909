import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function WaterproofingPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa]'>
      <Helmet>
        <title>Waterproofing Services - VBM Construction</title>
        <meta
          name='description'
          content="Explore VBM Construction's waterproofing services including foundation waterproofing, roof waterproofing, and more. Discover the benefits of waterproofing and how our expert team can protect your property from water damage."
        />
        <meta
          name='keywords'
          content='waterproofing services, foundation waterproofing, roof waterproofing, exterior wall waterproofing, VBM Construction, property protection'
        />
      </Helmet>
      <Header />
      <div className='h-auto bg-[#2b2b2b] flex items-center justify-center py-10'>
        <h1 className='text-[#fafafa] text-4xl md:text-5xl lg:text-6xl px-4 text-center'>
          Waterproofing
        </h1>
      </div>
      <div className='h-auto my-8 md:my-16 flex flex-col md:flex-row'>
        <div className='bg-[#2b2b2b] py-8 px-6 md:w-1/2'>
          <h2 className='text-[#fafafa] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Benefits of Waterproofing
          </h2>
          <ul className='text-[#fafafa] space-y-2'>
            {[
              'Enhanced Durability: Protects structures from water damage, extending their lifespan.',
              'Preventive Maintenance: Prevents mold growth and structural deterioration, reducing the need for costly repairs.',
              'Improved Indoor Air Quality: Reduces moisture infiltration, preventing mold and mildew growth.',
              'Weather Resistance: Provides robust protection against adverse weather conditions.',
              'Structural Integrity: Maintains the strength and stability of buildings by preventing water infiltration.',
            ].map((text, index) => (
              <li key={index} className='flex items-center'>
                <GoDotFill className='text-[#fafafa] text-base mr-3 flex-shrink-0' />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className='py-8 px-6 md:w-1/2 md:pl-10'>
          <h2 className='text-[#2b2b2b] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Our Services
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Foundation Waterproofing',
                'Roof Waterproofing',
                'Exterior Wall Waterproofing',
                'Balcony and Terrace Waterproofing',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Sealant Application',
                'Basement Waterproofing',
                'Crawl Space Waterproofing',
                'Interior Waterproofing',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
          </div>
          <h3 className='mt-4'>and more...</h3>
        </div>
      </div>
      <ContactUs />
    </div>
  );
}

export default WaterproofingPage;
