export const baySt = [
  'bay-st/bay1.jpg',
  'bay-st/bay2.jpg',
  'bay-st/bay3.jpg',
  'bay-st/bay4.jpg',
  'bay-st/bay5.jpg',
  'bay-st/bay6.jpg',
  'bay-st/bay7.jpg',
  'bay-st/bay8.jpg',
  'bay-st/bay9.jpg',
  'bay-st/bay10.jpg',
  'bay-st/bay11.jpg',
  'bay-st/bay12.jpg',
  'bay-st/bay13.jpg',
  'bay-st/bay14.jpg',
  'bay-st/bay15.jpg',
  'bay-st/bay16.jpg',
];

export const wardenAve = [
  'warden-ave/warden1.jpg',
  'warden-ave/warden2.jpg',
  'warden-ave/warden3.jpg',
  'warden-ave/warden4.jpg',
  'warden-ave/warden5.jpg',
  'warden-ave/warden6.jpg',
  'warden-ave/warden7.jpg',
  'warden-ave/warden8.jpg',
  'warden-ave/warden9.jpg',
  'warden-ave/warden10.jpg',
  'warden-ave/warden11.jpg',
  'warden-ave/warden12.jpg',
];

export const idaSt = [
  'ida-st/ida1.jpg',
  'ida-st/ida2.jpg',
  'ida-st/ida3.jpg',
  'ida-st/ida4.jpg',
  'ida-st/ida5.jpg',
  'ida-st/ida6.jpg',
  'ida-st/ida7.jpg',
  'ida-st/ida8.jpg',
  'ida-st/ida9.jpg',
  'ida-st/ida10.jpg',
];

export const bleeckerSt = [
  'bleecker-st/bleecker1.jpg',
  'bleecker-st/bleecker2.jpg',
  'bleecker-st/bleecker3.jpg',
  'bleecker-st/bleecker4.jpg',
  'bleecker-st/bleecker5.jpg',
  'bleecker-st/bleecker6.jpg',
  'bleecker-st/bleecker7.jpg',
  'bleecker-st/bleecker8.jpg',
];

export const regalRd = [
  'regal-rd/regal1.jpg',
  'regal-rd/regal2.jpg',
  'regal-rd/regal3.jpg',
  'regal-rd/regal4.jpg',
  'regal-rd/regal5.jpg',
  'regal-rd/regal6.jpg',
  'regal-rd/regal7.jpg',
  'regal-rd/regal8.jpg',
];

export const oakmountRd = [
  'oakmount-rd/oakmount1.jpg',
  'oakmount-rd/oakmount2.jpg',
  'oakmount-rd/oakmount3.jpg',
  'oakmount-rd/oakmount4.jpg',
  'oakmount-rd/oakmount5.jpg',
  'oakmount-rd/oakmount6.jpg',
  'oakmount-rd/oakmount7.jpg',
  'oakmount-rd/oakmount8.jpg',
];

export const stClaireAve = [
  'stclair-ave/stclair1.jpg',
  'stclair-ave/stclair2.jpg',
  'stclair-ave/stclair3.jpg',
  'stclair-ave/stclair4.jpg',
  'stclair-ave/stclair5.jpg',
  'stclair-ave/stclair6.jpg',
  'stclair-ave/stclair7.jpg',
  'stclair-ave/stclair8.jpg',
  'stclair-ave/stclair9.jpg',
  'stclair-ave/stclair10.jpg',
];

export const glenErinDr = [
  'glenerin-dr/glenerin1.jpg',
  'glenerin-dr/glenerin2.jpg',
  'glenerin-dr/glenerin3.jpg',
  'glenerin-dr/glenerin4.jpg',
  'glenerin-dr/glenerin5.jpg',
  'glenerin-dr/glenerin6.jpg',
  'glenerin-dr/glenerin7.jpg',
  'glenerin-dr/glenerin8.jpg',
  'glenerin-dr/glenerin9.jpg',
];
