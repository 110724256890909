import React from 'react';
import {
  MdLocalPhone,
  MdEmail,
  MdLocationOn,
  MdFiberManualRecord,
} from 'react-icons/md';
import { FaGlobe } from 'react-icons/fa';
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import '../Styles.css';

<div>
  <img className='h-24' src='/VBMWht.png' alt='logo' />
  <div className='text-[#fafafa] mt-2 text-center'>
    VBM Construction excels in restoring commercial, industrial, and residential
    properties. Our dedicated team ensures superior quality and craftsmanship on
    every project.
  </div>
</div>;

function ContactUs() {
  return (
    <div className='bg-[#2b2b2b]'>
      <Footer
        columns={[
          {
            title: (
              <div className='flex flex-col items-center'>
                <img
                  className='h-28 mx-auto md:mx-0 flex pb-2'
                  src='/VBMWht.png'
                  alt='logo'
                />
                <div className='max-w-60 text-center md:text-left mx-auto md:mx-0 text-[#fafafa] text-sm'>
                  VBM Construction excels in restoring commercial, industrial,
                  and residential properties. Our dedicated team ensures
                  superior quality and craftsmanship on every project.
                </div>
              </div>
            ),
          },
          {
            title: (
              <div className='text-[#fafafa] text-center md:text-left underline underline-offset-1'>
                CONTACT US
              </div>
            ),
            items: [
              {
                icon: (
                  <MdEmail style={{ color: '#fafafa', fontSize: '2rem' }} />
                ),
                title: 'vel@vbmconstruction.com',
                url: 'mailto:vel@vbmconstruction.com',
              },
              {
                icon: (
                  <MdLocalPhone
                    style={{ color: '#fafafa', fontSize: '2rem' }}
                  />
                ),
                title: '1-(416)-841-6427',
                url: 'tel:1-416-841-6427',
              },

              {
                icon: (
                  <FaGlobe style={{ color: '#fafafa', fontSize: '2rem' }} />
                ),
                title: 'vbmconstruction.com',
                url: '/',
              },
            ],
          },
          {
            title: (
              <div className='text-[#fafafa] text-center md:text-left underline underline-offset-1'>
                SERVICES
              </div>
            ),
            items: [
              {
                icon: (
                  <MdFiberManualRecord
                    style={{ color: '#fafafa', fontSize: '1rem' }}
                  />
                ),
                title: 'Masonry and Brick',
                url: '/masonryandbrick',
              },
              {
                icon: (
                  <MdFiberManualRecord
                    style={{ color: '#fafafa', fontSize: '1rem' }}
                  />
                ),
                title: 'Caulking and Sealant',
                url: '/caulking',
              },
              {
                icon: (
                  <MdFiberManualRecord
                    style={{ color: '#fafafa', fontSize: '1rem' }}
                  />
                ),
                title: 'Concrete Repair/ Balcony Restoration',
                url: '/concrete',
              },
              {
                icon: (
                  <MdFiberManualRecord
                    style={{ color: '#fafafa', fontSize: '1rem' }}
                  />
                ),
                title: 'Waterproofing',
                url: '/waterproofing',
              },
              {
                icon: (
                  <MdFiberManualRecord
                    style={{ color: '#fafafa', fontSize: '1rem' }}
                  />
                ),
                title: 'Painting',
                url: '/painting',
              },
              {
                icon: (
                  <MdFiberManualRecord
                    style={{ color: '#fafafa', fontSize: '1rem' }}
                  />
                ),
                title: 'EIFS/Stucco',
                url: '/eifsstucco',
              },
            ],
          },
        ]}
        bottom='© 2024 VBM Construction INC. All rights reserved.'
        backgroundColor='#2b2b2b'
        columnLayout='space-between'
        className='text-center md:text-left custom-footer'
      />
    </div>
  );
}

export default ContactUs;
