import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function BricksPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa]'>
      <Helmet>
        <title>Masonry and Brick Restoration Services | VBM Construction</title>
        <meta
          name='description'
          content="Explore VBM Construction's expert masonry and brick restoration services. Enhance durability, structural integrity, and aesthetic appeal with our professional solutions."
        />
        <meta
          name='keywords'
          content='masonry restoration, brick restoration, brick replacement, tuckpointing, VBM Construction, structural repair, historical preservation'
        />
      </Helmet>
      <Header />
      <div className='h-auto bg-[#2b2b2b] flex items-center justify-center py-10'>
        <h1 className='text-[#fafafa] text-4xl md:text-5xl lg:text-6xl px-4 text-center'>
          Masonry and Brick
        </h1>
      </div>
      <div className='h-auto my-8 md:my-16 flex flex-col md:flex-row'>
        <div className='bg-[#2b2b2b] py-8 px-6 md:w-1/2'>
          <h2 className='text-[#fafafa] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Benefits of Brick Restoration
          </h2>
          <ul className='text-[#fafafa] space-y-2'>
            {[
              'Enhanced Durability: Extends the lifespan of brickwork, protecting it from damage and weathering.',
              'Structural Integrity: Maintains and enhances safety by preventing further damage.',
              'Improved Aesthetic Appeal: Enhances visual appeal by preserving original charm and character.',
              'Historical Preservation: Maintains the historical and architectural significance of heritage buildings.',
              'Increased Property Value: Makes brick structures more attractive, boosting property value.',
            ].map((text, index) => (
              <li key={index} className='flex items-center'>
                <GoDotFill className='text-[#fafafa] text-base mr-3 flex-shrink-0' />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className='py-8 px-6 md:w-1/2 md:pl-10'>
          <h2 className='text-[#2b2b2b] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Our Services
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Brick Replacement',
                'Tuckpointing',
                'Lintel/Shelf Angle Restoration and Replacement',
                'Brick Tinting',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Chimney Repair/Restoration',
                'Parging',
                'Bricking in Doors and Windows',
                'Window Sill Replacement',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
          </div>
          <h3 className='mt-4'>and more...</h3>
        </div>
      </div>

      <ContactUs />
    </div>
  );
}

export default BricksPage;
