import React from 'react';

function ServiceBox({ icon, text }) {
  return (
    <div class='relative flex flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12 w-auto'>
      <div class='group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10'>
        <span class='absolute top-10 z-0 h-20 w-20 rounded-full bg-[#2b2b2b] transition-all duration-300 group-hover:scale-[12]'></span>
        <div class='relative z-10 mx-auto max-w-md'>
          <span class='grid h-20 w-20 place-items-center rounded-full bg-[#2b2b2b] transition-all duration-300 group-hover-[#2b2b2b'>
            {icon}
          </span>
          <div class='space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90'>
            <p>{text}</p>
          </div>
          <div class='pt-5 text-base font-semibold leading-7'>
            <p>
              <a
                href='#'
                class='transition-all duration-300 group-hover:text-white opacity-0 group-hover:opacity-100 text-xl'
              >
                Read more &rarr;
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceBox;
