import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import ImageSlider from '../components/ImageSlider';
import {
  baySt,
  idaSt,
  glenErinDr,
  bleeckerSt,
  regalRd,
  stClaireAve,
  oakmountRd,
  wardenAve,
} from '../locations.js';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function OurProjects() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa]'>
      <Helmet>
        <title>Our Projects - VBM Construction</title>
        <meta
          name='description'
          content="Explore VBM Construction's diverse portfolio of residential and commercial restoration projects. See our completed work and discover how we bring visions to life."
        />
        <meta
          name='keywords'
          content='construction, restoration, residential projects, commercial projects, portfolio, VBM Construction'
        />
      </Helmet>
      <Header />
      <div className='h-auto bg-[#2b2b2b] flex items-center justify-center py-10'>
        <h1 className='text-[#fafafa] text-6xl px-4'>Our Projects</h1>
      </div>

      <div className='h-auto mx-4 sm:mx-8 lg:mx-16 my-8 lg:my-16 border-y-4 border-[#2b2b2b]'>
        <h2 className='px-5 py-10 text-sm sm:text-base md:text-lg lg:text-xl'>
          From residential to commercial restorations, we take pride in
          delivering results that exceed expectations. Explore our portfolio to
          see the diverse range of projects we’ve undertaken, and discover how
          we can bring your vision to life.
        </h2>
      </div>

      <ImageSlider location='372 Bay St' imageArray={baySt} />
      <ImageSlider location='2800 Warden Ave' imageArray={wardenAve} />
      <ImageSlider location='24 Ida St' imageArray={idaSt} />
      <ImageSlider location='135 Bleecker St' imageArray={bleeckerSt} />
      <ImageSlider location='2 Regal Rd' imageArray={regalRd} />
      <ImageSlider location='45 Oakmount Rd' imageArray={oakmountRd} />
      <ImageSlider location='355 St Claire Ave W' imageArray={stClaireAve} />
      <ImageSlider location='3455 Glen Erin Dr' imageArray={glenErinDr} />

      <ContactUs />
    </div>
  );
}

export default OurProjects;
