import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function CaulkingPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa]'>
      <Helmet>
        <title>
          Professional Caulking and Sealant Services | VBM Construction
        </title>
        <meta
          name='description'
          content="Discover VBM Construction's expert caulking and sealant services. Enhance your property's durability, energy efficiency, and aesthetic appeal with our top-notch solutions."
        />
        <meta
          name='keywords'
          content='caulking services, sealant services, joint sealing, window caulking, energy efficiency, VBM Construction'
        />
      </Helmet>
      <Header />
      <div className='h-auto bg-[#2b2b2b] flex items-center justify-center py-10'>
        <h1 className='text-[#fafafa] text-4xl md:text-5xl lg:text-6xl px-4 text-center'>
          Caulking and Sealant
        </h1>
      </div>
      <div className='h-auto my-8 md:my-16 flex flex-col md:flex-row'>
        <div className='bg-[#2b2b2b] py-8 px-6 md:w-1/2'>
          <h2 className='text-[#fafafa] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Benefits of Caulking and Sealant Restoration
          </h2>
          <ul className='text-[#fafafa] space-y-2'>
            {[
              'Enhanced Durability: Extends the lifespan of structures by preventing air and water infiltration.',
              'Energy Efficiency: Reduces energy costs by sealing gaps and preventing drafts.',
              'Improved Aesthetic Appeal: Provides a clean and polished finish to joints and seams.',
              'Preventive Maintenance: Helps prevent damage and deterioration from moisture and pests.',
              'Increased Property Value: Improves the overall quality and appearance of the property, boosting its value.',
            ].map((text, index) => (
              <li key={index} className='flex items-center'>
                <GoDotFill className='text-[#fafafa] text-base mr-3 flex-shrink-0' />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className='py-8 px-6 md:w-1/2 md:pl-10'>
          <h2 className='text-[#2b2b2b] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Our Services
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Joint Sealing',
                'Window and Door Caulking',
                'Expansion Joint Caulking',
                'Concrete Crack Sealing',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Exterior Siding Sealing',
                'Roof and Flashing Sealing',
                'Firestop Caulking',
                'Waterproof Sealant Application',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
          </div>
          <h3 className='mt-4'>and more...</h3>
        </div>
      </div>

      <ContactUs />
    </div>
  );
}

export default CaulkingPage;
