function TextSlider() {
  return (
    <div>
      <main className='relative flex flex-col justify-center overflow-hidden'>
        <div className='w-full max-w-6xl mx-auto px-4 md:px-6 py-4'>
          <div className='text-center'>
            <div className='font-extrabold flex-col text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-[#2b2b2b] bg-clip-text'>
              We specialize in{' '}
              <span className='text-[#3B82F6] inline-flex flex-col h-[calc(1em*1.25)] sm:h-[calc(1em*1.25)] md:h-[calc(1em*1.25)] lg:h-[calc(1em*1.25)] overflow-hidden'>
                <ul className='block animate-text-slide text-center leading-tight'>
                  <li className='block'>Masonry and Bricks</li>
                  <li className='block'>Caulking and Sealant</li>
                  <li className='block'>Concrete/Balcony </li>
                  <li className='block'>Waterproofing</li>
                  <li className='block'>Painting</li>
                  <li className='block'>EIFS/Stucco</li>
                  <li aria-hidden='true' className='block'>
                    Masonry and Bricks
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TextSlider;
