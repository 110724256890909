import React from 'react';
import ServiceBox from './ServiceBox';
import { BsBricks } from 'react-icons/bs';
import { IoWaterOutline } from 'react-icons/io5';
import { ImPaintFormat } from 'react-icons/im';
import { Link } from 'react-router-dom';

function ServiceGrid() {
  return (
    <div className='flex flex-col items-center justify-center my-6'>
      
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
        <Link to='/masonryandbrick'>
          <ServiceBox
            icon={<BsBricks style={{ color: '#fafafa', fontSize: '2.5rem' }} />}
            text='Expert brick masonry repairs, delivering durable solutions and meticulous craftsmanship to enhance the structural integrity and aesthetic appeal.'
          />
        </Link>
        <Link to='/caulking'>
          <ServiceBox
            icon={<img className='w-14' src='caulking.svg' alt='caulking' />}
            text='Achieve optimal weatherproofing and structural protection through precise caulking and sealant applications, ensuring durability and resilience.'
          />
        </Link>
        <Link to='/concrete'>
          <ServiceBox
            icon={<img className='w-12' src='concrete.svg' alt='concrete' />}
            text='Enhance safety and aesthetics through expert concrete repair and balcony restoration, combining meticulous craftsmanship with durable solutions.'
          />
        </Link>
        <Link to='/waterproofing'>
          <ServiceBox
            icon={
              <IoWaterOutline
                style={{ color: '#fafafa', fontSize: '2.5rem' }}
              />
            }
            text='Professional waterproofing solutions, ensuring comprehensive protection against water damage for residential and commercial properties.'
          />
        </Link>
        <Link to='/painting'>
          <ServiceBox
            icon={
              <ImPaintFormat style={{ color: '#fafafa', fontSize: '2.5rem' }} />
            }
            text='Transform residential and commercial spaces with professional painting services, delivering high-quality finishes and enduring visual enhancement.'
          />
        </Link>

        <Link to='/eifsstucco'>
          <ServiceBox
            icon={<img className='w-12' src='trowel.svg' alt='trowel' />}
            text='Benefit from specialized EIFS and stucco applications, providing energy-efficient and aesthetically pleasing exterior solutions tailored to your property.'
          />
        </Link>
      </div>
    </div>
  );
}

export default ServiceGrid;
