import React from 'react';

const AboutUs = () => {
  return (
    <section className="bg-[#fafafa] py-12 md:py-16">
      <div className="max-w-6xl mx-auto px-4 md:px-8">
        <div className="text-[#2b2b2b] p-6 md:p-12 rounded-lg shadow-lg">
          <h2 className="text-xl md:text-4xl font-bold mb-10 text-center">
          Turning Visions into Reality with Expertise and Precision          
          </h2>
          <p className="mb-6">
          With over 20 years of expertise in the construction restoration industry, we are dedicated to revitalizing both residential and commercial properties. Our accomplished team brings extensive knowledge and skill to every project, focusing on enhancing structural integrity and visual appeal with a commitment to safety.
          </p>
          <p>
          We prioritize your unique vision and strive to deliver outcomes that surpass expectations. Our meticulous approach to safety, combined with clear communication and thoughtful budget management, ensures a seamless experience and peace of mind for all stakeholders. Our mission is to restore, renew, and elevate spaces, improving their functionality and aesthetic value.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
