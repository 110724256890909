import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slick.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: false,
  lazyLoad: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ImageSlider({ location, link, imageArray }) {
  const [enlargedImage, setEnlargedImage] = useState(null);

  const handleImageClick = (image) => {
    setEnlargedImage(image);
  };

  const handleCloseClick = () => {
    setEnlargedImage(null);
  };

  return (
    <div className="slider-container my-16 pb-10 mx-10">
      <a
        className="my-5 mx-5 font-medium text-4xl flex"
        href={`http://maps.google.com/?q=${location}`}
        target="_blank"
        rel="noreferrer"
      >
        {location}
      </a>

      <div className="mx-3 mt-8">
        <Slider {...settings}>
          {imageArray?.map((el) => (
            <img
              src={el}
              alt={el}
              key={el}
              className="px-5 rounded-lg"
              style={{ width: '100%', height: '200px', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => handleImageClick(el)}
            />
          ))}
        </Slider>
      </div>

      {enlargedImage && (
        <div className="overlay" onClick={handleCloseClick}>
          <img src={enlargedImage} alt={enlargedImage} className="enlarged-image" />
          <button className="close-button" onClick={handleCloseClick}>Close</button>
        </div>
      )}
    </div>
  );
}

export default ImageSlider;
