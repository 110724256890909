import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown menu
  const [isServicesOpen, setIsServicesOpen] = useState(false); // State to manage services dropdown

  const headerText = 'px-8 text-[#2b2b2b] font-medium text-xl slide-in-border';

  // Function to toggle dropdown menu and hamburger animation
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleServicesDropdown = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  return (
    <header className='sticky top-0 z-50 w-full bg-[#fafafa] flex justify-between h-20 items-center'>
      <Link to='/'>
        <div className='flex m-4'>
          <img className='h-12' src='/VBMLogo1.png' alt='logo' />
          <button className='ml-3 text-3xl font-medium text-[#2b2b2b] hidden md:block'>
            VBM CONSTRUCTION INC.
          </button>
        </div>
      </Link>
      <div className='flex items-center'>
        <div className='hidden lg:flex'>
          <Link to='/' className={headerText}>
            HOME
          </Link>
          <div className='relative'>
            <button
              onClick={toggleServicesDropdown}
              className={`${headerText} relative`}
            >
              SERVICES
            </button>
            {isServicesOpen && (
              <div className='absolute top-full left-0 bg-[#fafafa] shadow-md z-50'>
                <Link
                  to='/masonryandbrick'
                  className='block py-2 px-4 hover:bg-gray-200'
                >
                  Masonry and Brick
                </Link>
                <Link
                  to='/caulking'
                  className='block py-2 px-4 hover:bg-gray-200'
                >
                  Caulking and Sealant
                </Link>
                <Link
                  to='/concrete'
                  className='block py-2 px-4 hover:bg-gray-200'
                >
                  Concrete Repair/ Balcony Restoration
                </Link>
                <Link
                  to='/waterproofing'
                  className='block py-2 px-4 hover:bg-gray-200'
                >
                  Waterproofing
                </Link>
                <Link
                  to='/painting'
                  className='block py-2 px-4 hover:bg-gray-200'
                >
                  Painting
                </Link>
                <Link
                  to='/eifsstucco'
                  className='block py-2 px-4 hover:bg-gray-200'
                >
                  EIFS/Stucco
                </Link>
              </div>
            )}
          </div>
          <Link to='/projects' className={headerText}>
            PROJECTS
          </Link>
          <button
            onClick={(e) =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
              })
            }
            className={headerText}
          >
            CONTACT US
          </button>
        </div>

        {/* Hamburger menu for mobile */}
        <div className='lg:hidden'>
          <div
            className='group flex h-20 w-20 cursor-pointer items-center justify-center rounded-3xl p-2'
            onClick={toggleDropdown}
          >
            <div className='space-y-2'>
              <span
                className={`block h-1 w-10 origin-center rounded-full bg-[#2b2b2b] transition-transform ease-in-out ${
                  isOpen ? 'translate-y-1.5 rotate-45' : ''
                }`}
              ></span>
              <span
                className={`block h-1 w-8 origin-center rounded-full bg-[#2b2b2b] transition-transform ease-in-out ${
                  isOpen ? 'w-10 -translate-y-1.5 -rotate-45' : ''
                }`}
              ></span>
            </div>
          </div>
          {isOpen && (
            <div className='absolute top-full left-0 right-0 bg-[#fafafa] shadow-md z-50'>
              <Link to='/' className='block py-2 px-4 hover:bg-gray-200'>
                HOME
              </Link>
              <div className='relative'>
                <button
                  onClick={toggleServicesDropdown}
                  className='block py-2 px-4 hover:bg-gray-200 w-full text-left'
                >
                  SERVICES
                </button>
                {isServicesOpen && (
                  <div className='absolute left-0 bg-[#fafafa] shadow-md z-50'>
                    <Link
                      to='/masonryandbrick'
                      className='block py-2 px-4 hover:bg-gray-200'
                    >
                      Masonry and Brick
                    </Link>
                    <Link
                      to='/caulking'
                      className='block py-2 px-4 hover:bg-gray-200'
                    >
                      Caulking and Sealant
                    </Link>
                    <Link
                      to='/concrete'
                      className='block py-2 px-4 hover:bg-gray-200'
                    >
                      Concrete Repair/ Balcony Restoration
                    </Link>
                    <Link
                      to='/waterproofing'
                      className='block py-2 px-4 hover:bg-gray-200'
                    >
                      Waterproofing
                    </Link>
                    <Link
                      to='/painting'
                      className='block py-2 px-4 hover:bg-gray-200'
                    >
                      Painting
                    </Link>
                    <Link
                      to='/eifsstucco'
                      className='block py-2 px-4 hover:bg-gray-200'
                    >
                      EIFS/Stucco
                    </Link>
                  </div>
                )}
              </div>
              {/* <Link to='/gallery' className='block py-2 px-4 hover:bg-gray-200'>
                GALLERY
              </Link> */}
              <Link
                to='/projects'
                className='block py-2 px-4 hover:bg-gray-200'
              >
                PROJECTS
              </Link>
              <button
                onClick={(e) =>
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                  })
                }
                className='block py-2 px-4 hover:bg-gray-200'
              >
                CONTACT US
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
