import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function PaintingPage() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className='bg-[#fafafa]'>
      <Helmet>
        <title>Painting Services - VBM Construction</title>
        <meta
          name='description'
          content="Explore VBM Construction's painting services including exterior painting, surface preparation, and more. Learn about the benefits of painting and how our expert team can enhance your property's appearance and value."
        />
        <meta
          name='keywords'
          content='painting services, exterior painting, surface preparation, VBM Construction, home renovation, property improvement'
        />
      </Helmet>
      <Header />
      <div className='h-auto bg-[#2b2b2b] flex items-center justify-center py-10'>
        <h1 className='text-[#fafafa] text-4xl md:text-5xl lg:text-6xl px-4 text-center'>
          Painting
        </h1>
      </div>
      <div className='h-auto my-8 md:my-16 flex flex-col md:flex-row'>
        <div className='bg-[#2b2b2b] py-8 px-6 md:w-1/2'>
          <h2 className='text-[#fafafa] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Benefits of Painting
          </h2>
          <ul className='text-[#fafafa] space-y-2'>
            {[
              'Enhanced Aesthetic Appeal: Refreshes the look of your property, improving its overall appearance.',
              'Protection: Shields surfaces from environmental damage, such as UV rays, moisture, and mold.',
              'Personalization: Allows you to customize and personalize your space with colors and finishes that reflect your style.',
              'Cost-Effective Renovation: Provides a cost-effective way to renovate and update the look of your property without major construction.',
              'Increased Property Value: Enhances curb appeal and can boost the market value of your property.',
            ].map((text, index) => (
              <li key={index} className='flex items-center'>
                <GoDotFill className='text-[#fafafa] text-base mr-3 flex-shrink-0' />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className='py-8 px-6 md:w-1/2 md:pl-10'>
          <h2 className='text-[#2b2b2b] text-lg md:text-xl pb-6 md:pb-10 px-1'>
            Our Services
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Exterior Painting',
                'Surface Preparation',
                'Siding and Trim Painting',
                'Pressure Washing and Cleaning',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
            <ul className='text-[#2b2b2b] space-y-2'>
              {[
                'Roof Coating and Painting',
                'Window and Door Frame Painting',
                'Concrete and Stucco Painting',
                'Brick and Masonry Painting',
              ].map((service, index) => (
                <li key={index} className='flex items-center'>
                  <FaCheck className='text-[#2b2b2b] text-base mr-3 flex-shrink-0' />
                  {service}
                </li>
              ))}
            </ul>
          </div>
          <h3 className='mt-4'>and more...</h3>
        </div>
      </div>
      <ContactUs />
    </div>
  );
}

export default PaintingPage;
